import type { BlogPostFragment } from 'types';
import type { BlogCategory } from '@root/sanity/sanity.types';
import { PAGE_BLOG } from '@root/constants/routes';
import { formatMonth } from 'utils/utils';

const PRODUCTION_URL = 'https://generationtux.com';

export type ButtonStyle =
  | 'btn-default'
  | 'btn-info'
  | 'btn-primary'
  | 'btn-default-outline'
  | 'btn-info-outline'
  | 'btn-primary-outline';

export const buttonStyle = (value: ButtonStyle) => {
  if (!value) return 'btn-default';

  return value.includes('btn-') ? value : `btn-${value}`;
};

export const excerpt = ({ excerpt, bodyRaw: body }: BlogPostFragment) => {
  let trimmed;

  if (excerpt) {
    trimmed = excerpt.substr(0, 120);
    trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
  } else {
    trimmed = body
      .map((block: any) => {
        if (block._type !== 'block' || !block.children) {
          return '';
        }

        return block.children.map((child: any) => child.text).join('');
      })
      .join(' ')
      .substr(0, 120);
    trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')));
  }

  if (!trimmed) {
    return '';
  }

  return trimmed + (trimmed.endsWith('.') || trimmed.endsWith('?') || trimmed.endsWith('!') ? '' : '...');
};

export const formatPublishedAt = (dateString: string) => {
  if (!dateString) return '';

  const publishedAtDate = new Date(dateString.replace('Z', ''));

  return `${formatMonth(
    publishedAtDate.getMonth() + 1,
    publishedAtDate.getFullYear()
  )} ${publishedAtDate.getDate()}, ${publishedAtDate.getFullYear()}`;
};

export const BLOG_BASE_CANONICAL_URL = `${PRODUCTION_URL}${PAGE_BLOG}`;

/**
 * Use the first category as the main one for canonical urls
 */
export const getCanonicalUrlForBlogPost = (post: BlogPostFragment): string => {
  const categorySlug = post.blogCategories?.[0].slug!.current ?? 'all';

  return `${BLOG_BASE_CANONICAL_URL}/${categorySlug}/${post.slug!.current}`;
};

/**
 * Use the first category as the main one for canonical urls
 */
export const getCanonicalUrlForCategory = (category?: BlogCategory): string =>
  category ? `${BLOG_BASE_CANONICAL_URL}/${category.slug!.current}` : BLOG_BASE_CANONICAL_URL;
