import type { BlogPostFragment } from 'types';
import { PAGE_BLOG, PAGE_BLOG_POST } from '@root/constants/routes';
import Card from 'components/Card';
import BlogPicture from 'components/Blog/Picture';
import { excerpt, formatPublishedAt } from 'components/Blog/utils';

type BlogListProps = {
  activeBlogCategory?: string;
  posts: BlogPostFragment[];
};

const BlogList = ({ activeBlogCategory, posts }: BlogListProps) => (
  <section className="container">
    <div className="row mb-64 gap-y-32">
      {posts.map((post) => {
        const blogCategory = activeBlogCategory ? activeBlogCategory : post.blogCategories?.[0].slug.current || 'all';

        return (
          <Card
            as={`${PAGE_BLOG}/${blogCategory}/${post.slug?.current}`}
            caption={formatPublishedAt(post.publishedAt ?? '')}
            subTitle={post.author.name}
            className="col-span-12 sm:col-span-6 md:col-span-4"
            ctaTitle="Read More"
            href={PAGE_BLOG_POST}
            image={
              <BlogPicture
                alt={`${post.mainImage.alt}`}
                className="aspect-ratio-16by9"
                crop
                nativeWidth={post.mainImage.asset.metadata.dimensions?.width!}
                slot="moreCard"
                url={post.mainImage.asset.url}
              />
            }
            key={post._id}
            title={post.title ?? 'Title Missing'}
            trackerClass={`tracker-callout-blog-post_${post.slug?.current.replace(/-/g, '_')}-210920-134950`}
          >
            {excerpt(post)}
          </Card>
        );
      })}
    </div>
  </section>
);

export default BlogList;
