import Link from 'next/link';
import type { PropsWithChildren } from 'react';

import type { BlogCategory } from '@root/sanity/sanity.types';
import { PAGE_BLOG, PAGE_BLOG_CATEGORY } from '@root/constants/routes';
import BlogNavigation from 'components/Blog/Navigation';
import LogoIcon from 'components/LogoIcon';
import { BlogPostFragment } from 'types';

type Props = PropsWithChildren<{
  activeBlogCategory?: BlogCategory;
  activeBlogPost?: BlogPostFragment;
  categories: BlogCategory[];
  className?: string;
  pathname: string;
  showLogo?: boolean;
}>;

const LayoutBlog = ({
  activeBlogCategory,
  activeBlogPost,
  categories,
  className,
  children,
  pathname,
  showLogo = true,
}: Props) => (
  <div className="bg-gray-lighter py-1">
    <BlogNavigation categories={categories} pathname={pathname} />

    <div className={className ? className : ''}>
      {showLogo && (
        <div className="container mb-48 flex items-center pt-80">
          <Link href={PAGE_BLOG} as={PAGE_BLOG}>
            <a className="inline-flex items-center gap-16 align-top">
              <LogoIcon />
              {activeBlogPost ? (
                <span className="text-h1 border-l-1 border-black pl-16 uppercase">{'Blog'}</span>
              ) : (
                <h1 className="text-h1 border-l-1 border-black pl-16 uppercase">{'Blog'}</h1>
              )}
            </a>
          </Link>
          {activeBlogCategory?.slug?.current !== 'all' && (
            <Link href={PAGE_BLOG_CATEGORY} as={`${PAGE_BLOG}/${activeBlogCategory?.slug.current}`}>
              <a className="text-h1 pl-16 capitalize text-gray-dark">{activeBlogCategory?.categoryName}</a>
            </Link>
          )}
        </div>
      )}

      {children}
    </div>
  </div>
);

export default LayoutBlog;
