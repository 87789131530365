import type { Breakpoints } from 'types';
import { buildPictureAttributes } from 'components/pictureUitls';
import Link from 'next/link';
import { MediaLink } from '@root/sanity/sanity.types';
import { PropsWithChildren } from 'react';

const postHero: Breakpoints = {
  base: 343,
  xs: 524,
  sm: 676,
  md: 904,
  lg: 1192,
  xl: 1192,
};

const postHeroWithFeaturedProducts: Breakpoints = {
  base: 343,
  xs: 524,
  sm: 676,
  md: 670,
  lg: 884,
  xl: 884,
};

const postContent: Breakpoints = {
  base: 284,
  xs: 460,
  sm: 548,
  md: 776,
  lg: 1064,
  xl: 1064,
};

const postContentWithFeaturedProducts: Breakpoints = {
  base: 284,
  xs: 460,
  sm: 548,
  md: 542,
  lg: 755,
  xl: 755,
};

const indexHero: Breakpoints = {
  base: 480,
  xs: 524,
  sm: 676,
  md: 514,
  lg: 682,
  xl: 682,
};

const indexCard: Breakpoints = {
  base: 480,
  xs: 524,
  sm: 676,
  md: 592,
  lg: 592,
  xl: 592,
};

const moreCard: Breakpoints = {
  base: 343,
  xs: 524,
  sm: 322,
  md: 280,
  lg: 376,
  xl: 376,
};

const slots = {
  postHero,
  postHeroWithFeaturedProducts,
  postContent,
  postContentWithFeaturedProducts,
  indexHero,
  indexCard,
  moreCard,
};

type OptionalLink = Omit<MediaLink, '_type'>;

/**
 * @see https://tailwindcss.com/docs/object-fit
 */
type ObjectFit = 'contain' | 'cover' | 'fill' | 'scale-down' | 'none';

type BlogPictureProps = {
  slot: keyof typeof slots;
  url: string;
  alt: string;
  ratio?: string;
  crop?: boolean;
  focalPointY?: number;
  focalPointX?: number;
  nativeWidth: number;
  linksTo?: OptionalLink;
  fit?: ObjectFit;
  imgClassName?: string;
};

const MaybeLinkTo = (props: PropsWithChildren<{ link?: OptionalLink }>) => {
  if (!props.link) {
    return <>{props.children}</>;
  }

  return (
    <Link href={props.link.href ?? '#'}>
      <a data-testid="blog-picture-link" className={props.link.trackerClass}>
        {props.children}
      </a>
    </Link>
  );
};

const BlogPicture: React.FC<React.HTMLAttributes<HTMLPictureElement> & BlogPictureProps> = ({
  className = '',
  imgClassName,
  url,
  alt,
  ratio,
  focalPointX = 0.3,
  focalPointY = 0.5,
  slot,
  crop = false,
  nativeWidth,
  linksTo,
  fit = 'cover',
  ...props
}) => {
  const {
    aspectRatios,
    chunkedClasses,
    className: classWithBase,
    hasValidAr,
    screens,
  } = buildPictureAttributes(className, ratio ? '' : 'aspect-ratio-7by5');

  const imgWidths = slots[slot];

  imgClassName ??= `block h-full w-full object-${fit}`;

  interface SanityUrl {
    url: string;
    focalPointY: number;
    focalPointX: number;
    width: number;
    dpr: 1 | 2;
    aspectRatio: (typeof aspectRatios)[keyof Breakpoints];
  }

  const showWarnings = !hasValidAr && !ratio && process.env.ENVIRONMENT !== 'production';

  if (showWarnings)
    console.error(
      `Image uses an unsupported aspect ratio. Either edit the image to match one of the ratios already defined in the Tailwind theme file or define a new one.`
    );

  function buildSanityUrl({ width, aspectRatio, dpr, url, focalPointX, focalPointY }: SanityUrl) {
    let height;

    if (aspectRatio) {
      const [aspectWidth, aspectHeight] = (aspectRatio as string).split(':');
      const ratio = width / parseInt(aspectWidth, 10);
      height = Math.ceil(ratio * parseInt(aspectHeight, 10));
    }

    return `${url}?fm=webp&w=${width}&auto=format&dpr=${dpr}&${
      crop && height ? `fit=crop&crop=focalpoint&fp-x=${focalPointX}&fp-y=${focalPointY}&h=${height}` : 'fit=clip'
    }`;
  }

  return (
    <div {...props} className={`flex w-full justify-center ${chunkedClasses.noMatch?.join(' ')}`}>
      <div
        className={`max-h-[1000px] ${classWithBase.join(' ')}`}
        style={{
          aspectRatio: ratio,
          width: slot === 'postContent' || slot === 'postContentWithFeaturedProducts' ? 'auto' : '100%',
        }}
        data-testid="blog-picture-parent-div"
      >
        <MaybeLinkTo link={linksTo}>
          <picture className={`inline h-auto w-auto`}>
            <>
              {Object.keys(aspectRatios)
                .reverse()
                .map((elm) => (
                  <source
                    key={elm}
                    media={elm === 'base' ? undefined : `(min-width: ${screens[elm as keyof Breakpoints]})`}
                    srcSet={`${buildSanityUrl({
                      url,
                      focalPointX,
                      focalPointY,
                      aspectRatio: aspectRatios[elm as keyof Breakpoints],
                      dpr: 1,
                      width:
                        (imgWidths[elm as keyof Breakpoints] as number) > nativeWidth
                          ? nativeWidth
                          : (imgWidths[elm as keyof Breakpoints] as number),
                    })} 1x,
                    ${buildSanityUrl({
                      url,
                      focalPointX,
                      focalPointY,
                      aspectRatio: aspectRatios[elm as keyof Breakpoints],
                      dpr: 2,
                      width:
                        (imgWidths[elm as keyof Breakpoints] as number) > nativeWidth
                          ? nativeWidth
                          : (imgWidths[elm as keyof Breakpoints] as number),
                    })} 2x`}
                  />
                ))}

              <img className={imgClassName} loading="lazy" alt={alt} data-testid="blog-picture-image" />
            </>
          </picture>
        </MaybeLinkTo>
      </div>
    </div>
  );
};

export default BlogPicture;
