export const API_PATH = '/api' as const;

export const API_BLOG = `${API_PATH}/blog` as const;
export const API_BLOG_ALL = `${API_PATH}/blog/all` as const;
export const API_BLOG_POST = `${API_PATH}/blog/post` as const;
export const API_BLOG_CATEGORIES = `${API_PATH}/blog/categories` as const;

export const PAGE_BLOG = '/blog' as const;
export const PAGE_BLOG_CATEGORY = '/blog/[category]' as const;
export const PAGE_BLOG_ALL = '/blog/all' as const;
export const PAGE_BLOG_AUTHOR = '/blog/author/[slug]' as const;
export const PAGE_BLOG_POST = '/blog/[category]/[post]' as const;
