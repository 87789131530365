import Link from 'next/link';

import type { BlogCategory } from '@root/sanity/sanity.types';
import { PAGE_BLOG } from '@root/constants/routes';
import AnimateHeight from 'components/AnimateHeight';
import IconTriangleDown from 'components/IconTriangleDown';
import useVisible from 'hooks/useVisible';

type NavItem = { title: string; href: string };

type NavItemsProps = {
  className?: string;
  items: NavItem[];
  pathname: string;
  setIsVisible: (bool: boolean) => void;
};

const NavItems = ({ className, items, pathname, setIsVisible }: NavItemsProps) => (
  <nav className={`bg-gray-lighter p-16 ${className ? className : ''}`}>
    {items.map((item) => (
      <Link key={item.title} href={item.href}>
        <a
          onClick={() => setIsVisible(false)}
          className={`text-sm hover:underline${pathname === item.href ? ' text-gold-darker' : ' text-gray-darker'}`}
        >
          {item.title}
        </a>
      </Link>
    ))}
  </nav>
);

type BlogNavigationProps = {
  categories: BlogCategory[];
  pathname: string;
};

const BlogNavigation = ({ categories, pathname }: BlogNavigationProps): JSX.Element | null => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  if (categories?.length > 0) {
    const navItems = categories.map(({ categoryName, slug }) => ({
      href: `${PAGE_BLOG}/${slug!.current}`,
      title: categoryName!,
    }));

    return (
      <div ref={ref}>
        <div className="sm:hidden">
          <button onClick={() => setIsVisible(!isVisible)} className={`btn-default-outline btn w-full`}>
            Sub Menu <IconTriangleDown className={`${isVisible ? '' : 'rotate-90'}`} />
          </button>

          <AnimateHeight open={isVisible}>
            <NavItems
              className="flex flex-col space-y-4"
              items={navItems}
              pathname={pathname}
              setIsVisible={setIsVisible}
            />
          </AnimateHeight>
        </div>

        <NavItems
          className="hidden space-x-16 sm:flex md:pl-[164px]"
          items={navItems}
          pathname={pathname}
          setIsVisible={setIsVisible}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default BlogNavigation;
