import { MouseEvent } from 'react';

import IconArrowRight from 'components/IconArrowRight';
import IconArrowLeft from 'components/IconArrowLeft';

type NumberOrString = number | string;
type PageRange = Array<NumberOrString>;

const getRangeWithEllipses = (left: number, right: number, lastPage: number) => {
  let range = [] as PageRange,
    l = 0;

  //Building out the page range.
  for (let i = 1; i <= lastPage; i++) {
    if (i == 1 || i == lastPage || (i >= left && i < right)) {
      range.push(i);
    }
  }

  //Add ellipses before return
  return range.reduce((acc, currentValue) => {
    const currentValueNum = Number(currentValue);

    if (l) {
      if (currentValueNum - l === 1) {
        acc.push(currentValueNum);
      } else {
        acc.push('...');
      }
    }

    //This should add the first and last page in the correct order.
    if (acc.indexOf(currentValueNum) === -1) acc.push(currentValueNum);

    l = currentValueNum;

    return acc;
  }, [] as PageRange);
};

// Separated mobile and desktop pagination because using css to show them for different screen sizes and have slightly different logic.
export const paginationDesktop = (current: number, lastPage: number) => {
  const delta = 3;
  let left = 0,
    right = 0;

  /* - We need 9 pages shown with ellipses (left, right and middle) at any current page for greater than ten pages.
     - Ellipses on both sides don't show up until after page 5 */
  if (current < 6) {
    left = 0;
    right = current + (9 - current);
  } else if (current > lastPage - 5) {
    left = current - (current - lastPage + 7);
    right = lastPage;
  } else {
    left = current - delta;
    right = current + delta + 1;
  }

  return getRangeWithEllipses(left, right, lastPage);
};

export const paginationMobile = (current: number, lastPage: number) => {
  const delta = 1;
  let left = 0,
    right = 0;

  //We need 5 pages shown minus ellipses at any current page. Similar to Deskop and the
  // with point of inflection at 3.
  if (current < 3) {
    left = 0;
    right = current + (5 - current);
  } else if (current > lastPage - 2) {
    left = current - (current - lastPage + 3);
    right = lastPage;
  } else {
    left = current - delta;
    right = current + delta + 1;
  }

  return getRangeWithEllipses(left, right, lastPage);
};

type BlogPaginationProps = {
  activeBlogCategory: string;
  btnNext?: boolean | JSX.Element;
  btnPrev?: boolean | JSX.Element;
  page: number;
  totalPages: number;
  updatePage: (options: { categoryName: string; page: number }) => void;
} & ClassNameProp;

export const BlogPagination = ({
  activeBlogCategory,
  className = '',
  page,
  btnNext = true,
  btnPrev = true,
  totalPages,
  updatePage,
}: BlogPaginationProps) => {
  const pageRangeDesktop = paginationDesktop(page, totalPages);
  const pageRangeMobile = paginationMobile(page, totalPages);

  const handleClick = (ev: MouseEvent, pageNumber: NumberOrString) => {
    ev.preventDefault();
    updatePage({ categoryName: activeBlogCategory, page: Number(pageNumber) });
  };

  return (
    <div className={`container col-span-12 col-start-1 mb-64 flex items-center pt-16 text-center ${className}`}>
      {btnPrev &&
        (btnPrev === true ? (
          <div className="flex-grow">
            <button
              className="btn-info btn"
              disabled={page === 1 && page <= totalPages ? true : false}
              onClick={() => updatePage({ categoryName: activeBlogCategory, page: page - 1 })}
            >
              <IconArrowLeft />
              <div className="hidden md:inline">PREVIOUS PAGE</div>
            </button>
          </div>
        ) : (
          btnPrev
        ))}
      <div className="w-full text-sm">
        <div className="hidden md:inline">
          {pageRangeDesktop.map((pageNumber: NumberOrString) => (
            <div className="inline-block" key={`blog-pagination-${pageNumber}`}>
              <div className="mx-8 text-gold-darker">
                {pageNumber === '...' ? (
                  <span className="content-center text-black">{pageNumber}</span>
                ) : (
                  <a
                    className={`btn-text${
                      pageNumber === page ? ' cursor-default rounded bg-gray text-black' : ' cursor-pointer'
                    } content-center p-8 hover:rounded hover:bg-gray hover:text-black`}
                    href="#"
                    onClick={(ev: MouseEvent) => handleClick(ev, pageNumber)}
                  >
                    {pageNumber}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="inline md:hidden">
          {pageRangeMobile.map((pageNumber: NumberOrString) => (
            <div className="inline-block" key={`page-num-${pageNumber}`}>
              <div className="text-gold-darker xs:mx-8  sm:mx-16">
                {pageNumber === '...' ? (
                  <span className="content-center text-black">{pageNumber}</span>
                ) : (
                  <a
                    className={`btn-text${
                      pageNumber === page ? ' rounded bg-gray text-black' : ''
                    } content-center py-8 px-16 hover:rounded hover:bg-gray hover:text-black`}
                    href="#"
                    onClick={(ev: MouseEvent) => handleClick(ev, pageNumber)}
                  >
                    {pageNumber}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {btnNext &&
        (btnNext === true ? (
          <div className="flex-grow text-right">
            {page <= totalPages && (
              <button
                className="btn-info btn"
                disabled={page === totalPages ? true : false}
                onClick={() => updatePage({ categoryName: activeBlogCategory, page: page + 1 })}
              >
                <div className={`hidden md:inline`}>NEXT PAGE</div>
                <IconArrowRight />
              </button>
            )}
          </div>
        ) : (
          btnNext
        ))}
    </div>
  );
};

export default BlogPagination;
