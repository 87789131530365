import type { PropsWithChildren, ReactNode } from 'react';
import Link from 'next/link';
import IconArrowRight from './IconArrowRight';

type CardProps = PropsWithChildren<{
  as?: string;
  caption?: string;
  subTitle?: string;
  className?: string;
  ctaTitle?: string;
  href: string;
  image: ReactNode;
  title: string;
  trackerClass: string;
}>;

const Card = ({
  as,
  caption,
  subTitle,
  children,
  className,
  ctaTitle,
  href,
  image,
  title,
  trackerClass,
}: CardProps) => (
  <Link as={as} href={href}>
    <a
      className={`group flex w-full cursor-pointer flex-col justify-between bg-white transition-all  duration-200 ease-in-out hover:shadow-3 ${
        className ? className : ''
      } ${trackerClass}`}
    >
      {image}

      <div className="flex flex-grow flex-col items-start space-y-8 p-16 md:p-32">
        <div className="flex-grow space-y-16">
          <h3
            className="text-h4 mb-16 transition-all duration-200 ease-in-out group-hover:text-gold-darker"
            style={{ willChange: 'color' }}
          >
            {title}
          </h3>
          {subTitle && (
            <span className="font-['Roboto Condensed Bold'] mr-8 text-sm font-bold leading-none tracking-wide">
              {subTitle}
            </span>
          )}
          {caption && <span className="text-xs">{caption}</span>}

          {children && <div>{children}</div>}
        </div>

        {ctaTitle && (
          <div
            className="text-h5 flex items-center space-x-4 border-b-1 border-transparent text-gold-darker transition-all duration-200 ease-in-out hover:border-gold-darker"
            style={{ willChange: 'color' }}
          >
            <div>{ctaTitle}</div>
            <IconArrowRight className="!text-gold-darker" />
          </div>
        )}
      </div>
    </a>
  </Link>
);

export default Card;
